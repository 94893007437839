import { getRequestHeaders } from 'h3'

export function currentSlug(ctx, key = 'slug') {
  if (ctx?._route?.params?.[key]) {
    return ctx._route.params[key]
  } else {
    if (import.meta.client && window?.location?.pathname?.split('/')) {
      return window.location.pathname?.split('/').pop()
    } else {
      // TODO : not working, neet to fix
      const headers = getRequestHeaders(ctx)
      return headers.referer?.split('/').pop()
    }
  }
}

export function debounce(func, wait) {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      func(args)
    }, wait)
  }
}

export function uniqBy(arr, predicate) {
  const cb = typeof predicate === 'function' ? predicate : o => o[predicate]

  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item)

        map.has(key) || map.set(key, item)

        return map
      }, new Map())
      .values(),
  ]
}
